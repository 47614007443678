import { collection, addDoc } from "firebase/firestore";
import db from "./db";

interface LeadData {
  name: string;
  email: string;
  phone: string;
  message: string;
}

export default async function createLead(leadData: LeadData) {
  let result = null;
  let error = null;

  try {
    result = await addDoc(collection(db, "leads"), leadData);
  } catch (e) {
    error = e;
  }

  return { result, error };
}
