import React from "react";
import ReactDOM from "react-dom/client";
import "@fontsource-variable/open-sans";
import "@fontsource-variable/spline-sans";
import "@fontsource/sulphur-point";

import "./index.css";
import App from "./App";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import TherapistTemplates from "./components/TherapistTemplates";
import theme from "./style/theme";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ChakraProvider theme={theme}>
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/therapist-templates" element={<TherapistTemplates />} />
        </Routes>
      </div>
    </Router>
  </ChakraProvider>
);
