import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  Heading,
  Text,
  VStack,
  HStack,
  useColorModeValue,
  Icon,
  Card,
  CardBody,
  CardHeader,
  List,
  ListItem,
  ListIcon,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useToast,
} from "@chakra-ui/react";

import Footer from "./Footer";
import Hero from "./Hero";

const TherapistTemplates: React.FC = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <Box minH="100vh" backgroundColor={"#F9F9F9"}>
      <VStack>test therapist</VStack>

      <Footer />
    </Box>
  );
};

export default TherapistTemplates;
