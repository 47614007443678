// Import the functions you need from the SDKs you need
import { initializeApp, getApps } from "firebase/app";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBocZxh2T-SPm0X5u_i8t-AQzXgk3eQmp8",
  authDomain: "oxford-95292.firebaseapp.com",
  projectId: "oxford-95292",
  storageBucket: "oxford-95292.appspot.com",
  messagingSenderId: "696495664212",
  appId: "1:696495664212:web:3f8483277e51b6c3a991c1",
};

// Initialize Firebase
let firebase_app =
  getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

export default firebase_app;
