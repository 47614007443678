import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  Heading,
  Text,
  VStack,
  HStack,
  useColorModeValue,
  Icon,
  Card,
  CardBody,
  CardHeader,
  List,
  ListItem,
  ListIcon,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useToast,
} from "@chakra-ui/react";

import { FiStar, FiCheckCircle } from "react-icons/fi";
import Footer from "./components/Footer";
import Hero from "./components/Hero";

import createLead from "./firebase/createLead";

interface FeatureProps {
  icon: React.ReactElement;
  title: string;
  description: string;
}

interface FormData {
  name: string;
  email: string;
  phone: string;
  message: string;
}

const Feature: React.FC<FeatureProps> = ({ icon, title, description }) => (
  <HStack align="start" spacing={3}>
    <Box>{icon}</Box>
    <VStack align="start" spacing={1}>
      <Text fontWeight="semibold">{title}</Text>
      <Text fontSize="sm" color="gray.600">
        {description}
      </Text>
    </VStack>
  </HStack>
);

const LandingPage: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const isFormValid = formData.name && formData.email && formData.phone;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const { result, error } = await createLead(formData);
      if (error) {
        throw error; // Safely throwing the error
      }
      toast({
        title: "Successfully submitted",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setFormData({ name: "", email: "", phone: "", message: "" });
    } catch (error) {
      // Here, you ensure error is of type `Error`
      const errorMessage =
        error instanceof Error ? error.message : "Unknown error occurred";
      toast({
        title: "Error submitting",
        description: errorMessage,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box minH="100vh" backgroundColor={"#F9F9F9"}>
      <VStack>
        <Hero />

        <VStack
          as="section"
          id="features"
          spacing={8}
          w="full"
          minHeight={"50vh"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Heading
            as="h2"
            size={{ base: "sm", md: "md", lg: "lg" }}
            textAlign="center"
            fontWeight={400}
          >
            Why Choose Oxford Web Hosting?
          </Heading>
          <Grid
            backgroundColor={"white"}
            borderRadius={8}
            py={10}
            px={8}
            templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
            gap={8}
          >
            <Feature
              icon={<Icon as={FiCheckCircle} color="green.500" boxSize={5} />}
              title="Built for Therapists"
              description="Tailored web hosting designed specifically for counseling practices"
            />
            <Feature
              icon={<Icon as={FiStar} color="yellow.500" boxSize={5} />}
              title="Boston-Based"
              description="Local support that understands your practice and your clients"
            />
            <Feature
              icon={<Icon as={FiCheckCircle} color="green.500" boxSize={5} />}
              title="Stress-Free Management"
              description="We handle the tech, so you can focus on what matters - your patients"
            />
            <Feature
              icon={<Icon as={FiStar} color="yellow.500" boxSize={5} />}
              title="Affordable"
              description="Professional web presence starting at just $50/month"
            />
          </Grid>
        </VStack>

        <Container maxW="7xl">
          <VStack
            as="section"
            id="about"
            spacing={0}
            w="full"
            minHeight={"80vh"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Heading
              as="h2"
              size={{ base: "sm", md: "md", lg: "lg" }}
              textAlign="center"
              fontWeight={400}
            >
              Your Full-service Digital Innovation Partner
            </Heading>
            <Grid
              templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
              gap={8}
              w="full"
              mt={10}
              mb={14}
            >
              <Card>
                <CardHeader>
                  <Heading size={{ base: "sm", md: "md" }} fontWeight={400}>
                    Our Promise to You
                  </Heading>
                </CardHeader>
                <CardBody>
                  <List spacing={2}>
                    <ListItem>
                      <ListIcon as={FiCheckCircle} color="green.500" />
                      No long-term contracts
                    </ListItem>
                    <ListItem>
                      <ListIcon as={FiCheckCircle} color="green.500" />
                      30-day money-back guarantee
                    </ListItem>
                    <ListItem>
                      <ListIcon as={FiCheckCircle} color="green.500" />
                      Dedicated support from our Boston-based team
                    </ListItem>
                    <ListItem>
                      <ListIcon as={FiCheckCircle} color="green.500" />
                      Continuous improvements based on therapist feedback
                    </ListItem>
                  </List>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <Heading size={{ base: "sm", md: "md" }} fontWeight={400}>
                    Why Your Online Presence Matters:
                  </Heading>
                </CardHeader>
                <CardBody>
                  <List spacing={2}>
                    <ListItem>
                      76% of therapy seekers research online before choosing a
                      provider
                    </ListItem>
                    <ListItem>
                      A professional website builds trust with potential clients
                    </ListItem>
                    <ListItem>
                      Showcase your expertise and services to reach more people
                      in need
                    </ListItem>
                  </List>
                </CardBody>
              </Card>
            </Grid>
          </VStack>
        </Container>

        <VStack
          as="section"
          id="contact"
          spacing={4}
          textAlign="center"
          minHeight={"20vh"}
          justifyContent={"center"}
          mb={40}
        >
          <Container maxW="7xl">
            <Heading
              as="h2"
              size={{ base: "sm", md: "md", lg: "lg" }}
              fontWeight={400}
              mb={3}
            >
              Ready to Elevate Your Practice Online?
            </Heading>
            <Text
              size={{ base: "md", md: "lg", lg: "xl" }}
              color={useColorModeValue("gray.600", "gray.300")}
              mb={3}
            >
              Discover how Oxford can help your therapy practice thrive online.
            </Text>

            <VStack
              as="form"
              spacing={4}
              align="stretch"
              width={"100%"}
              onSubmit={(e) => handleSubmit(e)}
            >
              <FormControl isRequired>
                <FormLabel>Name</FormLabel>
                <Input
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  placeholder="Your name"
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Email</FormLabel>
                <Input
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="your@email.com"
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Phone</FormLabel>
                <Input
                  name="phone"
                  type="tel"
                  value={formData.phone}
                  onChange={handleInputChange}
                  placeholder="(123) 456-7890"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Message</FormLabel>
                <Textarea
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  placeholder="How can we help you?"
                />
              </FormControl>
              <Button
                type="submit"
                colorScheme="blackAlpha"
                backgroundColor={"black"}
                fontSize={"sm"}
                size="lg"
                mt={2}
                isDisabled={!isFormValid || isSubmitting}
                isLoading={isSubmitting}
              >
                Send Message
              </Button>
            </VStack>
          </Container>
        </VStack>
      </VStack>

      <Footer />
    </Box>
  );
};

export default LandingPage;
