import {
  Box,
  Container,
  Flex,
  HStack,
  Button,
  useColorModeValue,
  Text,
  Heading,
  VStack,
} from "@chakra-ui/react";
import { FiPhone, FiMail } from "react-icons/fi";
import { FiArrowUpRight } from "react-icons/fi";

import Appbar from "./Appbar";

const Hero = () => {
  return (
    <VStack
      position={"relative"}
      overflow="hidden"
      width={"100%"}
      as="section"
      id="hero"
      minHeight={"80vh"}
    >
      <Appbar />

      <Container maxW="7xl" zIndex={2}>
        <VStack
          spacing={4}
          textAlign="center"
          minHeight={"80vh"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Heading
            as="h1"
            size={{ base: "md", md: "lg", lg: "xl" }}
            color={useColorModeValue("gray.900", "white")}
            fontWeight={400}
          >
            Partnering with Mental Health Professionals to create timeless
            digital experiences.
          </Heading>
          <Text fontSize={{ base: "md", md: "lg", lg: "xl" }} color={"black"}>
            Secure, Professional, and Effortless. The Website Hosting Solution
            Therapists Trust
          </Text>
          <Flex
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={{ base: 3, md: 7 }}
            mt={6}
          >
            <Button
              size="lg"
              colorScheme="blackAlpha"
              backgroundColor={"blackAlpha.200"}
              fontSize={"0.8em"}
              borderRadius={"full"}
              fontWeight={400}
              color={"blackAlpha.800"}
              px={7}
              as="a"
              href="#contact"
            >
              Book a Free Consultation
            </Button>
            <Button
              colorScheme="blackAlpha"
              variant="outline"
              fontSize={"0.8em"}
              borderRadius={"full"}
              fontWeight={400}
              color={"blackAlpha.900"}
              size={"lg"}
              rightIcon={<FiArrowUpRight />}
              as="a"
              href="#features"
            >
              Learn More
            </Button>
          </Flex>
        </VStack>
      </Container>

      <Box
        position={"absolute"}
        top={0}
        bottom={0}
        left={0}
        right={0}
        width={"100%"}
        height={"100%"}
        minHeight={"100%"}
        minWidth={"100%"}
        bgGradient="linear(to-t, #f9f9f9, transparent)"
        zIndex={1}
      />

      <Box
        as="video"
        src={`${process.env.PUBLIC_URL}/img/bg.mp4`}
        autoPlay
        loop
        muted
        playsInline
        className="video-bg"
        position={"absolute"}
        top={0}
        bottom={0}
        left={0}
        right={0}
        width={"100%"}
        height={"100%"}
        minHeight={"100%"}
        minWidth={"100%"}
        zIndex={0}
        objectFit={"cover"}
      />
    </VStack>
  );
};

export default Hero;
