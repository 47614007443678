import { extendTheme } from "@chakra-ui/react";
import { theme as base } from "@chakra-ui/pro-theme";

const proTheme = extendTheme(base);

const extendedConfig = {
  colors: {
    ...proTheme.colors,
    brand: proTheme.colors.teal,
    primary: "black",
    secondary: "#DCDCDC",
  },
  fonts: {
    heading: "'Sulphur Point', -apple-system, system-ui, sans-serif",
    //body: "'Inter Variable', -apple-system, system-ui, sans-serif",
  },
};
const theme = extendTheme(extendedConfig, proTheme);

export default theme;
