import {
  Box,
  Container,
  Flex,
  HStack,
  Button,
  useColorModeValue,
  Text,
} from "@chakra-ui/react";
import { FiPhone, FiMail } from "react-icons/fi";

const Footer = () => {
  return (
    <Box as="footer">
      <Container maxW="7xl" py={8}>
        <Flex
          direction={{ base: "column", md: "row" }}
          justify="space-between"
          align="center"
        >
          <Text
            color={useColorModeValue("gray.600", "gray.300")}
            fontSize={{ base: "xs", md: "md" }}
            mb={{ base: 4, md: 0 }}
          >
            © 2024 Oxford. All rights reserved.
          </Text>
          {/* <HStack spacing={4}>
            <Button leftIcon={<FiPhone />} variant="ghost">
              (123) 456-7890
            </Button>
            <Button leftIcon={<FiMail />} variant="ghost">
              info@oxfordwebhosting.com
            </Button>
          </HStack> */}
        </Flex>
      </Container>
    </Box>
  );
};

export default Footer;
