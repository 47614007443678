import { Box, Container, Flex, HStack, Button } from "@chakra-ui/react";
import React from "react";
import Logo from "./Logo";

const Appbar = () => {
  return (
    <Box
      as="header"
      width={"100%"}
      zIndex={2}
      //bg={useColorModeValue("white", "gray.800")}
      //boxShadow="sm"
    >
      <Container maxW="7xl" py={3}>
        <Flex justify="space-between" align="center">
          <Logo width="120" height="40" isDark={false} />

          <HStack as="nav" spacing={{ base: 0, md: 4 }}>
            <Button
              variant="ghost"
              as="a"
              href="#features"
              fontWeight={500}
              color={"black"}
              size={{ base: "xs", md: "md" }}
            >
              Features
            </Button>

            <Button
              variant="ghost"
              as="a"
              href="#about"
              fontWeight={500}
              color={"black"}
              size={{ base: "xs", md: "md" }}
            >
              About
            </Button>

            <Button
              variant="ghost"
              as="a"
              href="#contact"
              fontWeight={500}
              color={"black"}
              size={{ base: "xs", md: "md" }}
            >
              Get Started
            </Button>
          </HStack>
        </Flex>
      </Container>
    </Box>
  );
};

export default Appbar;
